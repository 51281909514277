<template>
  <v-container>
    <!--Recommended Products-->
    <v-row class="mt-4 mb-5">
      <v-col cols="12" md="3" class="whiteBg">
        <h5>Top Selling</h5>
        <v-layout wrap>
          <v-col
            cols="12"
            v-for="(topProduct, index) in TopSellingProducts"
            :key="index"
          >
            <v-img height="200" :src="topProduct.productImage" full />
            <h6 class="mt-2">
              {{ systemSetting.systemCurrency }}
              {{ topProduct.productPrice }}
            </h6>
            <v-layout wrap>
              <v-rating
                v-model="rating"
                length="1"
                background-color="orange lighten-3"
                color="orange"
                small
              ></v-rating>
              <span class="black--text text--lighten-3 mr-5 mt-2 spanStyle">
                4.8
              </span>
              <span
                align="right"
                class="black--text text--lighten-2 text--lighten-3 mr-2 ml-5 mt-2 spanStyle"
              >
                647 Sold
              </span>
            </v-layout>
          </v-col>
        </v-layout>
      </v-col>
      <v-col cols="12" md="9">
        <h5>Seller Recommendation</h5>
        <v-row wrap>
          <v-col
            cols="6"
            md="3"
            v-for="(product, index) in recommendedProducts"
            :key="index"
          >
            <v-img
              height="100"
              :src="product.productImage"
              contain
              style="background-color:#ffffff;"
            />
            <div class="mt-2">
              {{ product.productName }}
            </div>
            <div>
              <b>
                {{ systemSetting.systemCurrency }}
                {{ product.productPrice }}
              </b>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RecommendedStoreProducts",

  data: () => ({
    rating: 1,
    TopSellingProducts: [
      {
        id: 1,
        productName: "Glococad",
        productCategory: "Anatacids",
        productDescription:
          "Glucocard Sensor Blood Glucose Test Strips - 50 ct",
        productPrice: "50",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632944202/5ec81f5e7964eoxynorm-20mg_1_qkpoko.png",
      },
      {
        id: 2,
        productName: "Band-Aids",
        productCategory: "Antifungals",
        productDescription:
          "Band-Aid Adhesive Bandages Variety Pack Assorted Sizes",
        productPrice: "40",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632944203/5bb46a015016aHydrochlorothiazide_1_5_kwvuk4.png",
      },
      {
        id: 3,
        productName: "Sunmark Benzoin",
        productCategory: "Antidiarrheals",
        productDescription:
          "Glucocard Sensor Blood Glucose Test Strips - 50 ct",
        productPrice: "25",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632944202/5ec81f5e7964eoxynorm-20mg_1_qkpoko.png",
      },
    ],
    recommendedProducts: [
      {
        id: 1,
        productName: "Glococad",
        productCategory: "Anatacids",
        productDescription:
          "Glucocard Sensor Blood Glucose Test Strips - 50 ct",
        productPrice: "50",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632944203/5bb46a015016aHydrochlorothiazide_1_5_kwvuk4.png",
      },
      {
        id: 2,
        productName: "Band-Aids",
        productCategory: "Antifungals",
        productDescription:
          "Band-Aid Adhesive Bandages Variety Pack Assorted Sizes",
        productPrice: "40",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632944203/5bb333b0e0edbMandrax-Quaalude_3_vyvie1.png",
      },
      {
        id: 3,
        productName: "Sunmark Benzoin",
        productCategory: "Antidiarrheals",
        productDescription:
          "Glucocard Sensor Blood Glucose Test Strips - 50 ct",
        productPrice: "25",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632308951/index_2_ygckuz.jpg",
      },
      {
        id: 4,
        productName: "Glococad",
        productCategory: "Anatacids",
        productDescription:
          "Glucocard Sensor Blood Glucose Test Strips - 50 ct",
        productPrice: "50",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632314423/product1_xjthss.png",
      },
      {
        id: 5,
        productName: "Band-Aids",
        productCategory: "Antifungals",
        productDescription:
          "Band-Aid Adhesive Bandages Variety Pack Assorted Sizes",
        productPrice: "40",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632308952/urso_hs93bk.jpg",
      },
      {
        id: 6,
        productName: "Sunmark Benzoin",
        productCategory: "Antidiarrheals",
        productDescription:
          "Glucocard Sensor Blood Glucose Test Strips - 50 ct",
        productPrice: "25",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632308951/index_2_ygckuz.jpg",
      },
      {
        id: 7,
        productName: "Band-Aids",
        productCategory: "Antifungals",
        productDescription:
          "Band-Aid Adhesive Bandages Variety Pack Assorted Sizes",
        productPrice: "40",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632308952/urso_hs93bk.jpg",
      },
      {
        id: 8,
        productName: "Sunmark Benzoin",
        productCategory: "Antidiarrheals",
        productDescription:
          "Glucocard Sensor Blood Glucose Test Strips - 50 ct",
        productPrice: "25",
        productImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632308951/index_2_ygckuz.jpg",
      },
    ],
    systemSetting: {
      systemCurrency: "₦",
    },
  }),
  methods: {},
};
</script>

<style scoped>
.priceStyle {
  width: 80px;
  height: auto;
  background-color: black;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.buttonStyle {
  margin-top: 10px;
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 10px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.whiteBg {
  background-color: #ffffff;
  padding: 20px;
}
</style>
